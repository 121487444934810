/* body,
html {
  margin: 0;
  padding: 0;
  min-height: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Saira", sans-serif !important;
}

/* html,
body {
  height: 100%;
  margin: 0;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
} */

/* #root {
  text-align: center;
} */
