/* @import "https://cdn.jsdelivr.net/npm/bulma@1.0.0/css/bulma.min.css"; */
@import url("https://fonts.googleapis.com/css2?family=Saira:wght@400;600&display=swap");

/* Override Bulma's default font-family */
html,
body,
button,
input,
select,
textarea,
h1,
h3,
.navbar,
.card,
.menu,
.subtitle,
.title,
.panel,
.modal-card-title {
  font-family: "Saira", sans-serif !important;
}

.main-container {
  display: flex;

  /*vertically stack children*/
  flex-direction: column;

  /* expand to take full height of page */
  /* min-height overrides both height & max-height */
  min-height: 100vh;
}

.dark-mode {
  background-color: #14161a !important;
  .navbar {
    background-color: #16181d !important;
    .navbar-menu > .navbar-end > a {
      color: white !important;
    }
    .navbar-brand > a {
      color: white !important;
    }

    .navbar-end > .navbar-menu a::after {
      background: #16181d !important;
      /* content: "";
      position: absolute;
      width: 2px;
      height: 20px;
      top: 40%;
      right: 0.1px; */
    }

    .navbar-menu > .navbar-dropdown {
      background: #16181d !important;
      color: white !important;
    }

    .navbar-menu > .navbar-dropdown > a {
      color: white !important;
    }

    .navbar-menu > a {
      background: #16181d !important;
      color: white !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .navbar-end a:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0.5rem;
      left: 0;
      background-color: white;
      visibility: hidden;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
    }
  }

  #navMenu {
    background-color: #16181d !important;
  }

  .welcomeTexthome > p {
    color: white;
  }

  .sectionHome {
    background-color: #1f2229;
    h1 {
      color: white;
    }
  }

  .aboutContentHome > h2 {
    color: white;
  }

  .aboutContentHome > p {
    color: white;
  }

  .sponsorTextContent > h2 {
    color: white;
  }

  .sponsorTextContent > p {
    color: white;
    strong {
      color: white;
    }
  }

  #aboutCard > h1 {
    color: white;
  }

  #aboutCard > p {
    color: white;
    strong {
      color: white;
    }
  }

  .boardMembersSection > h1 {
    color: white;
  }

  .sponsors-section > h2 {
    color: white;
  }

  .calendarWidget > h2,
  .calendarText {
    color: white;
  }
  footer {
    background-color: #1f2229;
    span,
    h3,
    div {
      color: white;
    }
  }
  .profile > .panel > .panel-block > strong {
    color: white;
  }

  .profile > .panel-block > .title {
    color: white;
  }

  .eCard > .card {
    background: #1f2229 !important;
    color: white;
  }

  .card > .card-content > .title,
  .subtitle {
    color: white !important;
  }

  .card > .card-content > .subtitle > strong {
    color: white !important;
  }

  #teamForm > .title,
  .field > .field-label > label {
    color: white;
  }

  .myEvents > .tile > .tile > .tile > .tile > article > p > strong {
    color: white;
  }

  .myEvents > .tile > .tile > .tile > .tile > article {
    background: #1f2229 !important;
  }

  .myEvents
    > .tile
    > .tile
    > .tile
    > .tile
    > article
    > #players
    > strong
    > ol
    > li {
    color: white;
  }

  .register > .section > .form > .label {
    color: white;
  }

  .login > .section > .form > .label {
    color: white;
  }

  .login > .section > .form > .divider > span {
    color: white;
  }

  .allTeams > h1 {
    color: white !important;
  }

  .allteams-body {
    background-color: black !important;
  }

  .fixtures-title,
  .tiesheet-title {
    color: white !important;
  }
}

.allteams-body > p {
  border-bottom: 1px solid grey;
}

/* #navcontainer {
  width: 100%;
  position: relative;
} */

.container {
  flex-grow: 0;
}

/* Navbar */
.navbar {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 25px;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid gray;
  /*box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;*/
}

.navbar-end > a {
  position: relative; /* Required for pseudo-elements */
}

.navbar-end > .navbar-menu a::after {
  background: white !important;
  /* content: "";
  position: absolute;
  width: 2px;
  height: 20px;
  top: 40%;
  right: 0.1px; */
}

.navbar-end a::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 20px;
  background-color: lightgrey;
  top: 40%;
  right: 0.1px;
}

.navbar-end a:last-child::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  background-color: lightgrey;
  top: 40%;
  right: 0.1px;
}

.is-scrolling {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 25px;
  transition: background-color 0.3s ease;
  background-color: #ebf5f0;
  /*background-color: #f5f5f5;*/
  border-bottom: 2px solid #00d1b2;
}

.navbar-end {
  margin-right: 50px;
}

@media only screen and (max-width: 600px) {
  .navbar {
    padding-left: 0;
    padding-right: 0;
  }
  /* .navbar-end a::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    background-color: lightgrey;
    top: 40%;
    right: 0.1px;
  } */
}

.navbar a {
  position: relative;
  color: #000;
  text-decoration: none;
}

.navbar-end a:hover {
  color: #00d1b2;
}

.navbar-brand {
  margin-left: 45px !important;
}

.navbar-brand a:hover {
  color: #00d1b2;
}

.navbar-end a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0.5rem;
  left: 0;
  background-color: #000;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.navbar-end a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

@media (max-width: 600px) {
  .navbar-brand {
    margin-left: 0 !important;
  }
  .club-name-nav-link {
    padding: 0 0.7vw 0 0;
  }
  .clubName {
    font-size: 4vw !important;
  }
  .theme-toggle {
    padding: 0 0.7vw 0 0.7vw;
  }
  .theme-toggle > .button {
    /* padding: 0 !important; */
    padding-left: 0.7rem !important;
    padding-right: 0.7rem !important;
    border-radius: 50% !important;
    height: 2rem;
  }
  .navbar-end {
    margin-right: 0;
  }
  @keyframes mymove {
    from {
      background-color: rgba(0, 0, 0, 0.05);
    }
    to {
      background-color: lightgrey;
    }
  }
  .navbar-burger {
    border-radius: 50%;
    /* margin-right: 0.1rem; */
    animation: mymove 5s infinite;
    height: 2rem !important;
    width: 2rem !important;
    /* margin-left: 0; */
  }
}

.theme-toggle > .button {
  border-radius: 50%;
}

@media (max-width: 600px) {
  .aboutContentHome > h2 {
    position: relative;
  }

  .aboutContentHome > h2::before {
    content: "";
    position: absolute;
    width: 20%;
    height: 2px;
    top: 4.5rem;
    left: 40% !important;
    background-color: lightgrey;
  }

  .sponsorTextContent > h2 {
    position: relative;
  }

  .sponsorTextContent > h2::before {
    content: "";
    position: absolute;
    width: 20%;
    height: 2px;
    top: 2.6rem;
    left: 38% !important;
    background-color: lightgrey;
  }

  #aboutCard > h1 {
    position: relative;
  }

  #aboutCard > h1::after {
    content: "";
    position: absolute;
    width: 20% !important;
    height: 2px;
    top: 2.6rem;
    left: 40% !important;
    background-color: lightgrey;
  }

  .boardMembersSection > h1 {
    position: relative;
  }

  .boardMembersSection > h1::after {
    content: "";
    position: absolute;
    width: 30%;
    height: 2px;
    top: 2.9rem;
    left: 34%;
    background-color: lightgrey;
  }
}

.aboutContentHome > h2 {
  position: relative;
}

.aboutContentHome > h2::before {
  content: "";
  position: absolute;
  width: 20%;
  height: 2px;
  top: 2.6rem;
  left: 5%;
  background-color: lightgrey;
}

.sponsors-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 80px;
}

.sponsors-section > h2 {
  position: relative;
}

.sponsors-section > h2::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 2px;
  top: 2.6rem;
  left: 25% !important;
  background-color: lightgrey;
}

.sponsor-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.himalayan-kitchen-logo {
  width: 150px !important;
  height: 150px !important;
  object-fit: cover;
  border-radius: 50%;
}

.pikkupihvi-logo {
  width: 100% !important;
  height: auto !important;
  object-fit: cover;
}

.themomocafe-logo {
  width: 150px !important;
  height: 150px !important;
  object-fit: cover;
  border-radius: 50%;
  border: 0.1px solid #03417a;
}

.restaurant-name {
  margin-top: 10px;
  font-size: 1.2em;
  font-weight: bold;
}

.restaurant-link {
  display: block;
  margin-top: 5px;
  font-size: 1em;
  /* color: #00d1b2; */
}

.sponsorTextContent > h2 {
  position: relative;
}

.sponsorTextContent > h2::before {
  content: "";
  position: absolute;
  width: 20%;
  height: 2px;
  top: 2.6rem;
  left: 8%;
  background-color: lightgrey;
}

@media only screen and (max-width: 600px) {
  .sponsor-logo-container {
    min-width: auto !important;
    display: flex;
    background-size: 100%;
    height: auto !important;
  }

  .image-column-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

#aboutCard > h1 {
  text-align: center;
  position: relative;
}

#aboutCard > h1::after {
  content: "";
  position: absolute;
  width: 5%;
  height: 2px;
  top: 2.6rem;
  left: 47.6%;
  background-color: lightgrey;
}

.boardMembersSection > h1 {
  position: relative;
}

.boardMembersSection > h1::after {
  content: "";
  position: absolute;
  width: 30%;
  height: 2px;
  top: 2.9rem;
  left: 34%;
  background-color: lightgrey;
}

.calendarWidget > h2 {
  position: relative;
}

.calendarWidget > h2::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 2px;
  top: 2.6rem;
  left: 24% !important;
  background-color: lightgrey;
}

/* HomePage */
.homePage {
  width: 100%;
  height: 100%;
  margin: auto;
  /* border: 2px solid red; */
  margin-top: 4.7rem;
  overflow: scroll;
}

.homeImage {
  background: url("./assests/images/teampic.jpeg");
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 60%;
  min-height: 55vh;
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

@media (max-width: 600px) {
  /* .homePage {
    margin-top: 5.7rem !important;
  } */
  .homeImage {
    min-height: 25.3vh;
  }
}
.sectionHome {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 55vh;
}

.sectionText {
  color: black;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.sectionHome > Link {
  width: auto;
}

h2 {
  font-family: Arial;
  font-size: 2.5vw;
}

h1 {
  font-family: Arial;
  font-size: 3.5vw;
}

@media only screen and (max-width: 600px) {
  .image {
    min-width: 100%;
    /*min-height: 35vh;*/
    display: flex;
    background-size: 100%;
    height: auto !important;
  }
}

.homeBanner {
  margin-left: 80px;
  margin-right: 80px;
  height: 90vh;
  /* border: 2px solid blue; */
  /* background: #f5f5f5; */
}

@media only screen and (max-width: 600px) {
  .homeBanner {
    margin-left: 10px;
    margin-right: 10px;
    height: auto;
  }
}

.welcomeTexthome > div {
  /* display: inline-flex; */
  font-size: 1.5rem;
}

/*animation*/
/* .welcomeTexthome > div {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1.5rem;
}

.welcomeTexthome > div:first-of-type {
  animation: showup 7s infinite;
}

.welcomeTexthome > div:last-of-type {
  width: 0px;
  animation: reveal 7s infinite;
}

.welcomeTexthome > div:last-of-type span {
  margin-left: -400px;
  animation: slidein 7s infinite;
}

@keyframes showup {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slidein {
  0% {
    margin-left: -800px;
  }
  20% {
    margin-left: -800px;
  }
  35% {
    margin-left: 0px;
  }
  100% {
    margin-left: 0px;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
    width: 0px;
  }
  20% {
    opacity: 1;
    width: 0px;
  }
  30% {
    width: 355px;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 585px;
  }
} */

#homeText {
  width: 100%;
  padding-top: 100px;
  margin-left: 0px;
  /*font-size: 3.5vw;*/
  font-family: Arial;
  margin-bottom: 40px;
  /* border-bottom: 10px solid gray; */
  color: black;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 600px) {
  #homeText {
    min-width: 100%;
    display: flex;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1rem;
    padding-top: 5rem;
    font-weight: bold;
    font-size: medium;
  }
}

@media (max-width: 600px) {
  .welcomeTexthome {
    padding: 0.1rem 1.3rem;
  }
}

#id,
.tag {
  display: flex;
  float: right;
  margin: auto;
}

.card {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media only screen and (max-width: 600px) {
  .card {
    flex-direction: column;
  }
  .sectionHome {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .sectionText {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: larger;
    font-weight: bold;
  }
  #getStarted {
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.1rem;
  }
}

@media only screen and (max-width: 600px) {
  .hero {
    display: flex;
    width: auto;
    margin-top: 1rem;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.hero {
  max-height: 500px;
  display: flex;
  justify-content: center;
}

.hero-body {
  margin: auto;
  text-align: center;
}

@media (max-width: 600px) {
  .aboutSection {
    margin: 0px 40px 5rem !important;
  }
  .aboutContentHome {
    text-align: center;
    margin-top: 1.2rem !important;
  }
}
#sponsors {
  /* height: 60vh; */
  padding: 25px;
  text-align: center;
  margin: auto;
}

@media (max-width: 800px) {
  .sponsors {
    height: auto !important;
    margin: 0px 40px 3rem !important;
  }
  .sponsorColumn {
    display: flex;
    flex-direction: column-reverse;
  }
  .sponsorImage > img {
    max-width: 100% !important;
    height: auto !important;
  }
  .sponsorTextContent {
    text-align: center;
  }
}

@media (max-width: 800px) {
  /* .calendarIframe > iframe {
    height: 550px !important;
  } */
  .calendarWidget > p {
    margin: 0 40px;
    font-size: 16px !important;
  }
  .eventWidget {
    width: 80% !important;
  }
  .eventWidget > iframe {
    width: 100% !important;
  }
}

.level-item {
  margin: 10px;
  padding: 5px;
}

.resimator {
  /* background: url("./assests/images/resimator.png"); */
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 175px;
  min-height: 175px;
  border-radius: 50%;
}

/* Register Page */
.register {
  display: flex;
  flex: 1 1;
  margin-top: 3.9rem;
}

.button {
  /* margin-top: 20px; */
}

.login {
  display: flex;
  flex: 1 1;
  margin-top: 3.9rem;
}

.section {
  display: flex;
  width: 100%;
}

.form {
  margin: auto;
  min-width: 30%;
}

@media only screen and (max-width: 600px) {
  .form {
    margin-left: auto;
    margin-right: auto;
    min-width: 100%;
  }
}

.label:not(:last-child) {
  margin-bottom: 0;
}

.input {
  margin-bottom: 10px;
}

.label {
  padding-bottom: 0px;
}

.link {
  color: #00d1b2;
  text-decoration: underline;
}

.link:hover {
  color: red;
}

.forgotPwd {
  text-align: center;
  margin-top: 50px;
  font-size: 20px;
}

.divider {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.divider hr {
  width: 100%;
  border: solid 1px #00d1b2;
}

.left {
  margin-right: 3%;
  margin-left: 10%;
}

.right {
  margin-left: 3%;
  margin-right: 10%;
}

.registerBtn {
  padding: auto;
}

.registerBtn a {
  margin: auto;
  width: 80%;
  margin-left: 10%;
}

.profile {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.profile > .panel {
  width: 35%;
  margin: 3rem;
  text-align: center;
}

.profile > .panel-block {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 3rem;
  min-width: max-content;
}

/* .panel-block > .button {
  font-size: 2vw;
} */

#rolePanel {
  margin: auto;
}

#adminBtn {
  margin: auto;
}

@media (max-width: 800px) {
  .profile {
    flex-direction: column;
  }
  .profile > .panel {
    margin: 6rem 0 0 0;
    width: 75%;
  }
  .profile > .panel-block {
    margin-top: 1.5rem;
    width: auto;
  }
  .profile > .panel-block > button {
    height: 3rem;
    font-weight: bold;
  }
}

@media only screen and (max-width: 600px) {
  .group-fixtures {
    display: flex;
    flex-direction: column !important;
    gap: 0.1rem !important;
  }
  .group-fixtures > .box {
    min-width: 100% !important;
  }
}

.team-maker {
  height: 100vh;
  margin-top: 4.7rem;
  border: 2px solid red;
}

.calendarWidget {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.widgetTab > li {
  padding: 0 !important;
}

.footer {
  clear: both;
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #f5f5f5;
  justify-content: center;
  align-items: center;
  padding: 0;
}

@media (max-width: 800px) {
  .footercolumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
  }
  .footercolumn > .columnone {
    margin: 1rem 0 1rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .columnone > #businessId {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footercolumn > .columntwo {
    margin: 0 0 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .columntwo > div {
    /* width: 100%; */
  }
  .footerContact {
    margin-right: 0 !important;
  }

  .footerContactDetails {
    display: flex;
    flex-direction: column;
    align-items: center !important;
  }

  .footerBusinessDetails {
    display: flex;
    flex-direction: column;
    align-items: center !important;
  }
  .social-media-links {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    margin-top: 0.7rem !important;
  }
}

.footercolumn {
  height: fit-content;
  margin-top: 3rem;
}

.columnone {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  margin-left: 80px;
}

.columntwo {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-end;
  width: 50%;
  margin-right: 80px;
}

.instaLink {
  background: #833ab4;
  background: linear-gradient(to right, #833ab4, #fd1d1d, #fcb045);
  border: none;
}

.content {
  margin: auto;
}

@media (max-width: 800px) {
  .admin {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    margin: 4.9rem 1rem 1rem 1rem;
    flex-direction: column;
  }
  .admin > #admindashboard {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    margin-right: 0;
    text-align: center;
  }

  #eventregister {
    width: 100%;
  }

  .allTeams > #teams {
    width: 100%;
    padding: 0 0.1rem 0 0.1rem;
  }

  #usersCard {
    height: 60vh;
    margin-top: 7%;
    margin-bottom: 6%;
    width: 100%;
    overflow: scroll;
    text-align: center;
    margin-right: 3%;
  }
}

.admin > .card {
  width: auto;
}

.admin {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1 1;
  margin-top: 3.9rem;
}

.admin > #admindashboard {
  justify-content: center;
  align-items: center;
  width: auto;
  text-align: center;
}

#admindashboard > .card-content > .button {
  margin: 10px;
}

#eventregister {
  height: auto;
  min-width: 30vw;
}

#eventform {
  width: 70%;
  padding: 0;
}

/* #usersCard {
  height: auto;
  width: 80vw;
  border: 2px solid green;
} */

#usersCard {
  height: 60vh;
  margin-top: 7%;
  margin-bottom: 6%;
  width: auto;
  overflow: scroll;
  text-align: center;
  margin-right: 1%;
}

#eventsPage {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  margin-top: 3.25rem;
}

@media (max-width: 800px) {
  #eventsPage {
    margin: 7rem 1rem 3rem 1rem;
  }
}

.eCard {
  display: flex;
  margin: auto;
}

#eventCard > .card > .card-content {
  margin: auto;
}

/* #eventCard {
  margin-left: 15px;
  margin-right: 15px;
} */

.teamPage {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  margin-top: 3.9rem;
}

#teamForm {
  height: auto;
}

#teamForm > #title {
  text-align: center;
}
@media (max-width: 800px) {
  .teamPage {
    margin-bottom: 1rem;
  }
  #teamForm {
    padding: 1rem 0.5rem 0.5rem 0.5rem;
  }
  #teamForm > .title {
    font-size: 1rem;
  }
}

#playerTag {
  /* //columns: 2; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 800px) {
  #playerTag > .tags-wrapper > .tag {
    font-size: 0.67rem;
  }
  /* .footer {
    padding: 2rem 1rem 3rem;
  } */
}

/* .label {
  width: max-content;
} */

.help {
  justify-content: center;
  margin: auto;
}

#delButton {
  background-color: #ff3860;
}

.about {
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

@media (max-width: 800px) {
  .members {
    display: flex;
    flex-direction: column !important;
    align-content: center;
  }

  .members > .card {
    /* margin: 0 !important; */
    width: 100% !important;
  }
}

.members > .card > .card-image > .image {
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  .media-content {
    overflow-x: inherit !important;
  }
}

#logoDiv {
  background: url("./assests/images/teamfamily.jpeg");
  height: 630px;
  width: 90%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /* border: 1px solid grey; */
  /* //margin: auto; */
}
@media (max-width: 800px) {
  #logoDiv {
    width: 100%;
    height: 12rem !important;
    margin-top: 0 !important;
  }
}

#aboutCard {
  width: 90%;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

#aboutContent {
  font-size: 1.1rem;
  text-align: justify;
}

.about {
  padding: 2rem 1.5rem !important;
}

.about > div {
  margin: auto;
  padding: 0;
}

.about > p {
  margin: auto;
}

@media (max-width: 800px) {
  .about {
    flex-direction: column;
  }
  #logoDiv {
    margin-top: 5rem;
  }
  #aboutCard {
    width: 100%;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  #aboutContent {
    font-size: inherit;
  }
  .boardMembersSection {
    margin-top: 0 !important;
  }
}

.memberContent > p {
  margin-bottom: 10px !important;
}

.memberContent > span {
  float: none !important;
  background-color: lightgrey !important;
}

.myEvents {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  margin-top: 3.9rem;
}

.myEvents > .tile.is-ancestor {
  margin: 2rem 0 2rem 0;
}

.myEvents > .progress {
  margin-left: 25%;
}

.myEvents > #warningMessage {
  margin-left: 45%;
}

.tile.is-ancestor {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tile.is-vertical > .tile {
  /* border: 1px solid red; */
}

#teamDetails {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: max-content;
}

#editEvent {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#eventsDetailsList {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem 1rem 3rem;
}

@media (max-width: 800px) {
  .myEvents {
    margin: 4.9rem 1rem 2rem 1rem;
  }
  #teamDetails {
    width: auto;
  }
  .myEvents > #warningMessage {
    margin-left: 30%;
  }
}

#myEvent {
  margin: auto;
  width: auto;
  display: flex;
}

#myEvent > .card {
  align-items: center;
  width: fit-content;
}

/* li {
  padding: 1px !important;
} */

#players > strong {
  margin: auto;
}

.updateEvent {
  height: 100vh;
}

.updateEvent > p {
  background-color: red;
  height: 50vh;
  margin-top: 20%;
}

.allTeams {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 7rem;
  margin-bottom: 3.9rem;
  width: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.allTeams > #teams {
  min-width: 70%;
}

#getPlayers {
  padding: 0rem 2rem 0rem 2rem;
}

/* .allTeams > * {
  margin: 10px;
} */

#teamForm > .field > .field-body {
  flex-grow: 4;
}

.field-label.is-normal {
  padding-top: 0;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.slide-in {
  animation: slideIn 10s linear infinite;
}

.livescore-container {
  margin-top: 5rem;
  max-width: 100%; /* Ensures it doesn’t exceed the viewport width */
  padding-left: 0.5rem; /* Adds some padding */
  padding-right: 0.5rem; /* Adds some padding */
  margin-bottom: 2rem;
}
